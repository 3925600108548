var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        size: "xl",
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "dialog-class": "w-fit-content"
      },
      on: {
        close: function($event) {
          return _vm.onCloseModal()
        }
      },
      model: {
        value: _vm.modal.isOpen,
        callback: function($$v) {
          _vm.$set(_vm.modal, "isOpen", $$v)
        },
        expression: "modal.isOpen"
      }
    },
    [
      _c(
        "b-row",
        { staticClass: "px-4" },
        [
          !_vm.isRotate
            ? _c("b-col", { attrs: { cols: "12" } }, [
                _c("p", { staticClass: "primary fs-12 text-justify" }, [
                  _vm._v(
                    " Para garantizar una mejor visualización de la tarjeta de pago y su correcta lectura en dispositivos de escaneo óptico, coloca tu dispositivo en posición horizontal. "
                  )
                ]),
                _c("div", { staticClass: "text-center" }, [
                  _c("img", {
                    staticClass: "img-fluid",
                    attrs: {
                      src: require("@/assets/images/anothers/rotar.gif")
                    }
                  })
                ])
              ])
            : _c(
                "b-col",
                { staticClass: "content-center", attrs: { cols: "12" } },
                [
                  _c("img", {
                    staticClass: "img-fluid",
                    attrs: {
                      src: "data:image/png;base64," + this.archivo,
                      alt: "tarjeta recaudo"
                    }
                  })
                ]
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }